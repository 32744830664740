import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

const NotFound = () => (
  <div>
    <Helmet>
      <html lang="en" />
      <meta name="description" content="Harsh's Portfolio" />
      <title>Harsh Verma - 404</title>
    </Helmet>
    <div>
      Sorry, this page does not exist. Go back to <Link to="/">home page</Link>
    </div>
  </div>
)

export default NotFound
